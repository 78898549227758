<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"  @datachange="datachange"/>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 측정년도 -->
            <c-datepicker
              name="measYear"
              type="year"
              :range="true"
              label="측정년도"
              v-model="year"
              @datachange="datachange"
            ></c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              codeGroupCd="WORK_MEAS_QUARTER_CD"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="measQuarter"
              label="분기"
              v-model="searchParam.measQuarter"
              @datachange="datachange"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-text
              :editable="editable"
              :disabled="true"
              :afterIcon="[
                { name: 'search', click: true, callbackName: 'searchHazard' },
                { name: 'close', click: true, callbackName: 'removeHazard' }
              ]"
              label="유해인자"
              name="hazardNames"
              v-model="searchParam.hazardNames"
              @searchHazard="openHazard"
              @removeHazard="removeHazard">
            </c-text>
          </div>
        </template>
      </c-search-box>
      <div class="col-12">
        <apexchart 
          ref="chart" 
          height="680" 
          type="line"
          :width="chart.chartWidth"
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'work-measure-result-graph',
  components: {
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        measQuarter: null,
        measYear: '',
        startYmd: '',
        endYmd: '',
        hazardNames: '',
        measCompleteFlag: null,
        hazardCds: '',
      },
      year: ['', ''],
      chart: {
        chartOptions: {
          title: {
            text: '작업환경측정치 통계'
          },
         // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '항목수치'
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
        series: [
          {
            name: "항목수치",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sai.workmeas.result.chart.url;
      // 년도 설정
      let years = [];
      for (let i = 0; i < 2; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.year = [ years[0], years[1]];

      this.getList();
    },
    getList() {
      if (!this.year) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message:
          '측정년도를 선택하세요',
          type: 'warning', // success / info / warning / error
        });
        // this.year[0] = this.searchParam.startYmd;
        // this.year[1] = this.searchParam.endYmd;
        return;
      }
      if (this.year && this.year.length > 0) {
        this.searchParam.startYmd = this.year[0];
        this.searchParam.endYmd = this.year[1];
      } 
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.chart.series = [];
        let xaxis = [];

        let len = _result.data.filter((_item, _idx) => {
          return _result.data.findIndex((__item, __idx) => {
            return _item.workMeasurementPlanId === __item.workMeasurementPlanId
          }) === _idx;
        }).length
        let idx = 0;
        this.$_.forEach(_result.data, _item => {
          xaxis.push(_item.measPlanContent)
          if (_item.measValues) {
            this.$_.forEach(_item.measValues, __item => {
              if (this.$_.findIndex(this.chart.series, { hazardCd: __item.hazardCd }) === -1) {
                this.chart.series.push({
                  hazardCd: __item.hazardCd,
                  name: __item.hazardName,
                  data: Array.from({length : len}, () => 0),
                })
              }
              
              this.chart.series[this.$_.findIndex(this.chart.series, {hazardCd: __item.hazardCd })]
                .data[idx] = __item.measValue
            })
          }
            idx ++;
        })
        this.chart.chartOptions.xaxis.categories = xaxis
        setTimeout(() => {
            this.chart.chartWidth = '100%';
          }, 200);
        this.$refs['chart'].refresh();
      },);
    },
    datachange() {
      this.getList();
    },
    openHazard() {
      this.popupOptions.title = '유해인자 검색'; // 유해인자 검색
      this.popupOptions.param = {
        type: 'multiple',
        workEnvFlag: 'Y',
      };
      this.popupOptions.target = () => import(`${'./harmfulFactorsPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let sum = 0;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.searchParam.hazardList, { hazardCd: _item.hazardCd }) === -1) {
            sum++;
            if (sum === 1) {
              this.searchParam.hazardCds +=  _item.hazardCd
              this.searchParam.hazardNames +=  _item.hazardName
            } else if (_item.length !== sum) {
              this.searchParam.hazardCds +=  ', ' +  _item.hazardCd;
              this.searchParam.hazardNames +=  ', ' +  _item.hazardName;
            }
          }
        })
        this.getList();
      }
    },
    removeHazard() {
      this.searchParam.hazardCds = ''
      this.searchParam.hazardNames = '';
      this.getList();
    },
  }
};
</script>